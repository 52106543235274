import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { JucyCountryCode } from '@jucy/rentals-common';
import { action, runInAction } from 'mobx';
import { AgentHirerDetailsFormValues } from '../../../components/Forms/HirerDetailsForm/AgentHirerDetailsForm';
import { DirectHirerDetailsFormValues } from '../../../components/Forms/HirerDetailsForm/DirectHirerDetailsSchema';
import { setHirerDetailsFromFormValues } from '../../../components/Forms/HirerDetailsForm/components/SyncHirerWithTripStore';
import { useRentalsApi, useRouteNavigate, useUpdateRouteParams } from '../../../hooks';
import ErrorReporter from '../../../lib/ErrorReporter';
import { subscribeToList } from '../../../lib/getJucyRentalsApiClient';
import { resolveError } from '../../../lib/resolveError';
import AppStateStore from '../../../store/AppStateStore';
import reservationStore from '../../../store/ReservationStore';
import { BookingCart } from '../../../types/BookingCart';
import { TripTabPanelProps } from '../components';

const copyHirerDetails = action(
    (
        data:
            | { values: AgentHirerDetailsFormValues; mode: 'agent' | 'staff' }
            | {
                  values: DirectHirerDetailsFormValues;
                  mode: 'direct';
              },
        destination: Partial<BookingCart>
    ) => {
        const commonProps: ['firstName', 'lastName', 'mobileNumber', 'email'] = ['firstName', 'lastName', 'mobileNumber', 'email'];
        const directProps: ['driversLicenceCountry'] = ['driversLicenceCountry'];
        destination.hirerDetails = destination.hirerDetails || new BookingCart().hirerDetails;
        for (const prop of commonProps) {
            destination.hirerDetails[prop] = data.values[prop]?.trim();
        }
        destination.hirerDetails.numberOfPeople = data.values.numberOfPeople;
        if (data.mode === 'direct') {
            destination.hirerDetails.acceptedTerms = data.values.termsAndConditions;
            destination.hirerDetails.mailingList = Boolean(data.values.mailingList);
            destination.paymentTypeId = data.values.paymentType;

            for (const prop of directProps) {
                destination.hirerDetails[prop] = data.values[prop]?.trim();
            }
        }
        if ('velocityMembershipId' in data.values) {
            destination.hirerDetails.loyaltyId = {
                type: 'velocity',
                id: data.values.velocityMembershipId,
            };
        }
        if (data.mode === 'agent' || data.mode === 'staff') {
            destination.hirerDetails.acceptedTerms = true;
            destination.hirerDetails.voucherReference = data.values.agentVoucherReference?.trim();
            destination.hirerDetails.agentName = data.values.agentName?.trim();
        }
    }
);

export const useHandleOnHirerDetailsSubmitted = ({ summary }: { summary?: BookingCart }): TripTabPanelProps['onHirerDetailsSubmit'] => {
    const updateRoute = useUpdateRouteParams();
    const navigate = useRouteNavigate();
    const rentalsApiClient = useRentalsApi();

    return useCallback(
        async (data, formikHelpers) => {
            formikHelpers.setStatus(undefined);
            formikHelpers.setSubmitting(true);
            if (!summary) {
                return;
            }
            copyHirerDetails(data, summary);
            if (summary.amendments) {
                copyHirerDetails(data, summary.amendments);
            }

            if (data.mode === 'direct') {
                try {
                    setHirerDetailsFromFormValues(data.values);
                    const booking = await rentalsApiClient.convertQuote(summary);
                    if (!booking) {
                        formikHelpers.setStatus({ error: 'Failed to create reservation' });
                        formikHelpers.setSubmitting(false);
                        return;
                    }
                    runInAction(() => {
                        summary.paymentToken = booking.paymentToken;
                        summary.convertUrl = booking.convertUrl;
                        summary.reservationReference = booking.reference;
                        summary.reservationId = booking.bookingNo;
                    });
                    if (booking.status === 'payment-required' && booking.convertUrl && summary) {
                        updateRoute(
                            { activeTab: 'payment' },
                            {
                                search: {
                                    reservationReference: `${summary.reservationReference}`,
                                    reservationId: `${summary.reservationId}`,
                                },
                                keepSearch: true,
                            }
                        );
                    }
                    if (data.values.mailingList) {
                        subscribeToList({
                            list: summary?.pickUpLocation?.CountryCode as JucyCountryCode,
                            email: data.values.email,
                            firstName: data.values.firstName,
                            lastName: data.values.lastName,
                            tags: ['obe', 'confirm-quote'],
                        }).catch();
                    }
                } catch (e) {
                    formikHelpers.setSubmitting(false);
                    const error = await resolveError(e);
                    if (error.message.toLowerCase() === 'dnr error') {
                        AppStateStore.showDNRModal = true;
                    } else if (error.message.toLowerCase() === 'loyalty error') {
                        formikHelpers.setFieldError(
                            'velocityMembershipId',
                            'Unable to verify your Velocity membership number. Please ensure the primary booking holder / driver details match the Velocity account.'
                        );
                    } else {
                        formikHelpers.setStatus({ error: error.message });
                    }
                }
            } else {
                try {
                    if (!summary?.selectedExcess) {
                        toast.error('Excess Reduction product must be selected to save');
                    } else {
                        if (summary.action === 'edit' && summary.amendments?.asCreateRequest) {
                            const createRequest = summary.amendments.asCreateRequest();
                            if (createRequest && !summary.isQuote) {
                                await rentalsApiClient.v3.reservations.updateReservation(summary.reservationReference as string, {
                                    ...createRequest,
                                });
                                toast.success('Amendments saved');
                                reservationStore.quotes = reservationStore.quotes.filter((q) => q.ReservationReference !== summary.reservationReference);
                                navigate('quote', { params: { reservationReference: summary.reservationReference } });
                            }
                            if (createRequest && summary.isQuote) {
                                await rentalsApiClient.v3.quotes.updateQuote(summary.reservationReference as string, {
                                    ...createRequest,
                                });
                                toast.success('Amendments saved');
                                reservationStore.quotes = reservationStore.quotes.filter((q) => q.ReservationReference !== summary.reservationReference);
                                navigate('quote', { params: { reservationReference: summary.reservationReference } });
                            }
                        } else {
                            const createRequest = summary?.asCreateRequest();
                            if (createRequest) {
                                const booking = await rentalsApiClient.v3.reservations.createBooking({
                                    ...createRequest,
                                    type: 'quote',
                                });
                                toast.success('Quote saved');
                                navigate('quote', {
                                    params: {
                                        reservationReference: booking.reference,
                                    },
                                });
                            }
                        }
                    }
                } catch (e) {
                    formikHelpers.setSubmitting(true);
                    const { error, message } = await resolveError(e);
                    runInAction(() => {
                        ErrorReporter.reportError({ error });
                        formikHelpers.setStatus({ error: message });
                        toast.error(message);
                        console.error(e);
                    });
                }
            }
            formikHelpers.setSubmitting(false);
        },
        [navigate, rentalsApiClient, summary, updateRoute]
    );
};
