import React, { useState } from 'react';
import DirectHirerDetailsFormComponent, {
    DirectHirerDetailsFormProps as DirectHirerDetailsFormComponentProps,
} from '../../../../../components/Forms/HirerDetailsForm/DirectHirerDetailsForm';
import { DirectHirerDetailsFormValues } from '../../../../../components/Forms/HirerDetailsForm/DirectHirerDetailsSchema';
import SyncHirerWithTripStore from '../../../../../components/Forms/HirerDetailsForm/components/SyncHirerWithTripStore';
import hireDetailsStore from '../../../../../store/HireDetailsStore';
import { BookingCart } from '../../../../../types/BookingCart';

export type DirectHirerDetailsFormProps = {
    summary: BookingCart;
    displayEmailQuote?: boolean;
    onSubmit: DirectHirerDetailsFormComponentProps['onSubmit'];
};

export const DirectHirerDetailsForm: React.FC<DirectHirerDetailsFormProps> = ({ summary, displayEmailQuote, onSubmit }) => {
    const [initialValues] = useState<Partial<DirectHirerDetailsFormValues>>(() => ({
        firstName: hireDetailsStore.isDefaultHirerName() ? '' : hireDetailsStore.firstName || '',
        lastName: hireDetailsStore.isDefaultHirerName() ? '' : hireDetailsStore.lastName || '',
        email: hireDetailsStore.isDefaultHirerEmail() ? '' : hireDetailsStore.email || '',
        mobileNumber: hireDetailsStore.mobileNumber?.trim() || '',
        paymentType: summary?.isOnRequest ? 'full' : summary?.paymentType?.id || 'deposit',
        driversLicenceCountry: hireDetailsStore.driversLicenceCountry || '',
        termsAndConditions: Boolean(hireDetailsStore.acceptedTerms),
        mailingList: Boolean(hireDetailsStore.mailingList),
        numberOfPeople: hireDetailsStore.numberOfPeople || ('' as unknown as number),
        velocityMembershipId: hireDetailsStore.loyaltyId.type === 'velocity' ? hireDetailsStore.loyaltyId.id || '' : '',
    }));
    return (
        <DirectHirerDetailsFormComponent summary={summary} initialValues={initialValues} onSubmit={onSubmit} displayEmailQuote={displayEmailQuote}>
            <SyncHirerWithTripStore />
        </DirectHirerDetailsFormComponent>
    );
};
DirectHirerDetailsForm.displayName = 'DirectHirerDetailsForm';
