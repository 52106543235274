import { observer } from 'mobx-react-lite';
import React from 'react';
import { AvailabilityGrid } from '../../../../components/FleetCategory';
import { AvailabilityGridProps } from '../../../../components/FleetCategory/components/AvailabilityGrid/AvailabilityGrid';
import { useSearchParams } from '../../../../hooks';
import AvailabilityStore from '../../../../store/AvailabilityStore';
import { BookingCart } from '../../../../types/BookingCart';
import { AgentAvailabilityCaption, DirectAvailabilityCaption, DirectMoreOptionsCaption } from './TripAvailabilityList';

export interface TripResultsTabProps extends Partial<AvailabilityGridProps> {
    summary?: BookingCart;
    onSelect: AvailabilityGridProps['onSelect'];
}

const TripResultsTab: React.FC<TripResultsTabProps> = observer(({ summary, onSelect, ...props }) => {
    const [searchParams] = useSearchParams<{ fleetCategoryCode?: string }>();
    if (!AvailabilityStore.hasTripInfo) {
        return null;
    }
    return (
        <AvailabilityGrid
            {...props}
            highlightedCode={searchParams.fleetCategoryCode}
            activeCode={AvailabilityStore.selectedFleetCategory?.CategoryCode}
            loading={AvailabilityStore.state === 'loading'}
            fleetCategories={AvailabilityStore.availableFleetCategories}
            onSelect={onSelect}
            caption={summary?.userMode === 'agent' ? <AgentAvailabilityCaption /> : <DirectAvailabilityCaption />}
            moreOptionsTitle="More options"
            moreOptionsCaption={summary?.userMode === 'agent' ? undefined : <DirectMoreOptionsCaption />}
            action={summary?.action}
        />
    );
});
TripResultsTab.displayName = 'TripResultsTab';
export default TripResultsTab;
