import config from '../config';
import appState from '../store/AppStateStore';
import { BookingCart } from '../types/BookingCart';
import { Base64Utils } from '#/lib/Base64Utils';

const createCheckInLink = (summary: BookingCart): URL => {
    const checkInUrl = new URL('/checkin', appState.brand === 'jucy' ? config.dashboardUrl.jucy : config.dashboardUrl.star);
    const lastName = summary.hirerDetails?.lastName;
    const bookingNumber = summary.reservationId;
    const pickUpBranchCode = summary.pickUpLocation?.SiteCode;
    if (lastName || bookingNumber || pickUpBranchCode) {
        checkInUrl.searchParams.set(
            'loginHint',
            Base64Utils.encode({
                bookingNumber,
                lastName,
                email: summary.hirerDetails?.email,
                pickUpBranchCode,
            })
        );
    }
    checkInUrl.searchParams.set('bookingNumber', `${summary.reservationId}`);
    checkInUrl.searchParams.set('pickUpBranch', `${summary.pickUpLocation?.SiteCode}`);
    checkInUrl.searchParams.set('reservationReference', summary.reservationReference || '');
    checkInUrl.searchParams.set('utm_source', 'obe');
    checkInUrl.searchParams.set('utm_campaign', 'check-in');
    checkInUrl.searchParams.set('utm_medium', 'check-in-btn');
    checkInUrl.searchParams.set('utm_id', 'pre-pick-up');
    return checkInUrl;
};
export default createCheckInLink;
