export class Base64Utils {
    static btoa(data: string): string {
        if (typeof window !== 'undefined') {
            return window.btoa(data);
        }
        return Buffer.from(data).toString('base64');
    }

    static atob(data: string): string {
        if (typeof window !== 'undefined') {
            return window.atob(data);
        }
        return Buffer.from(data, 'base64').toString();
    }

    static encode<T>(data: T): string {
        return Base64Utils.btoa(JSON.stringify(data));
    }

    static decode<T>(data: string): T {
        return JSON.parse(Base64Utils.atob(data)) as T;
    }

    static toArrayBuffer(data: string) {
        return Buffer.from(data, 'base64');
    }
}
