import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormikHelpers } from 'formik/dist/types';
import { JucyErrorAlert } from '../../../../components/Alert/JucyErrorAlert';
import { AgentHirerDetailsFormValues } from '../../../../components/Forms/HirerDetailsForm/AgentHirerDetailsForm';
import { DirectHirerDetailsFormValues } from '../../../../components/Forms/HirerDetailsForm/DirectHirerDetailsSchema';
import { BookingCart } from '../../../../types/BookingCart';
import { AgentHirerDetailsForm, AgentHirerDetailsFormProps, DirectHirerDetailsForm, DirectHirerDetailsFormProps } from './TripHirerDetails';

export interface TripHirerDetailsTabProps {
    summary?: BookingCart;
    onSubmit: (
        data:
            | { values: AgentHirerDetailsFormValues; mode: 'agent' }
            | {
                  values: DirectHirerDetailsFormValues;
                  mode: 'direct';
              },
        formikHelpers: FormikHelpers<AgentHirerDetailsFormValues | DirectHirerDetailsFormValues>
    ) => void | Promise<void>;
}

const TripHirerDetailsTab: React.FC<TripHirerDetailsTabProps> = observer(({ summary, onSubmit }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (!summary || !summary?.hirerDetails) {
        return (
            <JucyErrorAlert title="Whoops an unexpected error occurred">
                No active booking found
                <br />
                Return to the <Link to="/">Search Page</Link>
            </JucyErrorAlert>
        );
    }

    const handleSubmit = useCallback(
        (values: AgentHirerDetailsFormValues | DirectHirerDetailsFormValues, formikHelpers: FormikHelpers<AgentHirerDetailsFormValues | DirectHirerDetailsFormValues>) => {
            if (summary.userMode === 'direct') {
                onSubmit({ values: values as DirectHirerDetailsFormValues, mode: summary.userMode }, formikHelpers);
            } else {
                onSubmit({ values: values as AgentHirerDetailsFormValues, mode: 'agent' }, formikHelpers);
            }
        },
        [onSubmit, summary.userMode]
    );
    return (
        <>
            {summary.userMode === 'direct' ? (
                <DirectHirerDetailsForm summary={summary} displayEmailQuote={summary.canEmailQuote} onSubmit={handleSubmit as unknown as DirectHirerDetailsFormProps['onSubmit']} />
            ) : (
                <AgentHirerDetailsForm summary={summary} onSubmit={handleSubmit as AgentHirerDetailsFormProps['onSubmit']} />
            )}
        </>
    );
});
TripHirerDetailsTab.displayName = 'TripHirerDetailsTabs';
export default TripHirerDetailsTab;
