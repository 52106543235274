import { runInAction } from 'mobx';
import AvailabilityStore from './AvailabilityStore';
import hireDetailsStore from './HireDetailsStore';
import rentalTripSearchStore from './RentalTripSearchStore';
import reservationStore from './ReservationStore';
import tripStore from './TripStore';
import rewardPointsStore from './RewardPointsStore';

const initStores = () => {
    AvailabilityStore.reset();
    rentalTripSearchStore.init();
    hireDetailsStore.init();
    tripStore.init();
    rewardPointsStore.init();
    runInAction(() => {
        rentalTripSearchStore.state = 'done';
        reservationStore.activeQuote = undefined;
    });
};

export default initStores;
