import AppStateStore from '#/store/AppStateStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { JucyErrorAlert } from '../../../../components/Alert/JucyErrorAlert';
import PaymentForm from '../../../../components/Forms/PaymentForm/PaymentForm';
import { useActiveRoute, useSearchParams } from '../../../../hooks';
import RouteRegistry from '../../../../store/RouteRegistry';
import { BookingCart } from '../../../../types/BookingCart';

export interface TripPaymentTabProps {
    summary?: BookingCart;
}
const TripPaymentTab: React.FC<TripPaymentTabProps> = observer(({ summary }) => {
    const [searchParams] = useSearchParams();
    const activeRoute = useActiveRoute();
    const activeParams = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
        AppStateStore.orderSummaryFooter.setShowFooter(false);
        return () => {
            AppStateStore.orderSummaryFooter.setShowFooter(true);
        };
    }, []);
    const backLink = useMemo(
        () =>
            RouteRegistry.getPath(
                activeRoute?.id as string,
                {
                    ...activeParams,
                    activeTab: summary?.userMode === 'agent' ? 'hirer-details' : 'driver',
                },
                searchParams as Record<string, string>
            ),
        [activeParams, activeRoute?.id, searchParams, summary?.userMode]
    );
    if (!summary) {
        return (
            <JucyErrorAlert title="Whoops an unexpected error occurred">
                No active booking found
                <br />
                Return to the <Link to="/">Search Page</Link>
            </JucyErrorAlert>
        );
    }

    return (
        <Box
            className="animate__animated animate__fadeIn"
            sx={(t) => ({
                position: 'absolute',
                top: t.settings?.appBar.spacer(),
                left: 0,
                right: 0,
                background: t.palette.common.white,
                bottom: 0,
                zIndex: 10,
                pt: 2,
            })}
        >
            <PaymentForm
                backLink={
                    <Link className="smaller fw-bold" to={backLink}>
                        <i className="fa fa-chevron-left" aria-hidden="true" /> Back to driver info
                    </Link>
                }
                summary={summary}
            />
        </Box>
    );
});
TripPaymentTab.displayName = 'TripPaymentTab';
export default TripPaymentTab;
