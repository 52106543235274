import { Booking, BookingOptions } from '@jucy/rentals-api-client/rentals-api-v3';
import { Site } from '../../lib/api/model/Site';
import { BookingCart } from '../../types/BookingCart';
import SitesStore from '../SitesStore';

export type SummaryFromBookingProps = Partial<BookingCart> & { booking: Booking; options?: BookingOptions };
export const summaryFromBooking = ({ booking, options, ...props }: SummaryFromBookingProps): BookingCart => {
    const selectedExcess = booking.lines?.find((line) => line.category === 'InsuranceFee');
    const mandatoryItems = booking.lines?.filter((line) => line.productId && line.isMandatory).sort((p) => p.sortOrder || 0) || [];
    const addedProductIds = new Set([
        selectedExcess?.productId,
        ...mandatoryItems.map((line) => line.productId),
        ...(options?.bundles?.reduce((result, bundle) => [...result, ...bundle.bundledProducts.map((p) => p.productId)], [] as string[]) || []),
    ]);
    const selectedExtras = booking.lines?.filter((line) => !line.isMandatory && !addedProductIds.has(line.productId)) || [];
    const bookingCart = {
        ...props,
        pickUpLocation: SitesStore.getSiteByCode(booking.pickUpLocation) as Site,
        dropOffLocation: SitesStore.getSiteByCode(booking.dropOffLocation) as Site,
        hirerDetails: booking.hirerDetails
            ? {
                  firstName: booking.hirerDetails.firstName,
                  lastName: booking.hirerDetails.lastName,
                  mobileNumber: booking.hirerDetails.mobileNumber,
                  email: booking.hirerDetails.email,
                  driversLicenceCountry: booking.hirerDetails.driversLicence?.country || '',
                  acceptedTerms: true,
                  mailingList: booking.hirerDetails.mailingList,
                  numberOfPeople: booking.hirerDetails.numberOfPeople,
                  loyaltyId: booking.hirerDetails.loyalty,
              }
            : undefined,
        pickUpDate: booking?.pickUpDate,
        dropOffDate: booking?.dropOffDate,
        rentalDays: booking.rentalDays,
        fleetCategory: booking.fleetCategory,
        secondaryProducts: options?.secondaryProducts,
        insuranceProducts: options?.insuranceProducts,
        selectedExcess: selectedExcess,
        mandatoryItems: mandatoryItems,
        selectedExtras,
        reservationReference: booking.reservationReference,
        reservationId: booking.reservationId,
        bundles: options?.bundles || [],
        payments: booking.payments || [],
    };
    return new BookingCart({
        ...bookingCart,
        amendments: new BookingCart(bookingCart),
    });
};
